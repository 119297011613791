<template>
  <div class="result-container">
    <lot-nav-bar
      v-if="isMobile"
      title="历史开奖记录"
      :curTab="curTab"
      :titleSize="18"
      border="top"
      :shadow="true"
      class="lot-nav-bar"
      @handleChangeTab="handleChangeTab" />
    <lot-nav-bar
      v-else
      title="历史开奖记录"
      :curTab="curTab"
      class="lot-nav-bar"
      @handleChangeTab="handleChangeTab" />
    <div class="content">
      <!-- <transition> -->
        <keep-alive>
          <component :is="curTab" />
        </keep-alive>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import { goPage } from '@/utils/route'
import { allLots } from '@/constants/lots'
import LotNavBar from '@/components/LotNavBar'
import LotHH from './contents/LotHH'
import LotPK10 from './contents/LotPK10'
import Lot11P5 from './contents/Lot11P5'
import LotQ3 from './contents/LotQ3'
import Happy81012 from './contents/Happy81012'
// import LotMM from './contents/LotMM'
import LotNation from './contents/LotNation'
import LotOversea from './contents/LotOversea'
import LotOther from './contents/LotOther'
import { isMobile } from '@/utils/device'

export default {
  components: {
    LotNavBar,
    LotHH,
    LotPK10,
    Lot11P5,
    LotQ3,
    Happy81012,
    // LotMM,
    LotNation,
    LotOversea,
    LotOther
  },
  data () {
    return {
      curTab: this.initCurTab(),
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    }
  },
  methods: {
    initCurTab () {
      if (this.$route.query.tab) {
        return this.$route.query.tab
      }
      return 'LotHH'
    },
    handleChangeTab (tab) {
      this.curTab = tab.id
      goPage({
        name: 'LotteryResult',
        query: {
          tab: this.curTab,
          id: this.initLotId()
        }
      })
    },
    initLotId () {
      return (
        allLots
          .filter(item => item.id === this.curTab)[0]
          .children[0].id
      )
    }
  },
  watch: {
    '$route.query.tab' () {
      this.curTab = this.initCurTab()
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 575.98px) {}
@media (min-width: 576px) and (max-width: 767.98px) {}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {
  .result-container {
    min-width: 450px;
    padding: 20px 0;
  }
}
</style>
